<template>
  <div class="checkout__header">
    <div class="checkout__steps">
      <div class="step step--1 step--active" v-bind:class="{'step--confirmed': step === '3'}">
          <span>
            <svg class="icon i24x24">
              <use xlink:href="#i-cart_checked"></use>
            </svg>
            1. {{ t('label.personalize') }}
          </span>
      </div>
      <div class="step step--2" v-bind:class="{'step--active': step === '2', 'step--confirmed': step === '3'}">
        <span>
          <svg class="icon i24x24">
            <use xlink:href="#i-card"></use>
          </svg>
          2. {{ t('label.payment') }}
        </span>
      </div>
      <div class="step step--3" v-bind:class="{'step--active step--confirmed': step === '3'}">
        <span>
          <svg class="icon i24x24">
            <use xlink:href="#i-checkstep"></use>
          </svg>
          3. {{ t('label.confirmation') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";

export default {
  name: 'checkout-header-line',
  setup() {
    const i18n = useI18n();

    return {t: i18n.t};
  },
  props: {
    step: String,
  }
};
</script>