import {ref} from 'vue'
import {ERROR_TYPES} from "@/constants";
import { isProductRetail } from "@/helpers/ProductHelper";

const invalidFields = ref({});
const clear = (...keys) => {
	if (keys.length) {
		keys.forEach((key) => delete invalidFields.value[key])
	} else {
		invalidFields.value = {}
	}
};

const isFieldInvalid = (key) => !!invalidFields.value[key];
const isFormInvalid = () => Object.keys(invalidFields.value).length > 0;

const getFieldError = (key) => invalidFields.value[key];

const validEmail = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return re.test(email);
}

const scrollToErrorElement = () => {
	const firstInvalidFieldId = Object.keys(invalidFields.value)[0];
	if (firstInvalidFieldId) {
		document
			.querySelector("#" + firstInvalidFieldId + 'container')
			.scrollIntoView({behavior: 'smooth', block: 'center'});
	}
};

const redemptionParamsValidate = (params, maxAmount) => {
	if (!params.pin) {
		invalidFields.value['pin'] = ERROR_TYPES.required;
	} else if (params.pin.length < 2) {
		invalidFields.value['pin'] = ERROR_TYPES.too_short;
	} else if (params.pin.length > 15) {
		invalidFields.value['pin'] = ERROR_TYPES.too_long;
	} else {
		delete invalidFields.value['pin'];
	}

	if (!params.amount) {
		invalidFields.value['amount'] = ERROR_TYPES.required;
	} else if (params.amount > maxAmount) {
		invalidFields.value['amount'] = ERROR_TYPES.exceeded_max_amount;
	} else {
		delete invalidFields.value['amount'];
	}

	if (params.comment && params.comment.length < 2) {
		invalidFields.value['comment'] = ERROR_TYPES.too_short;
	} else if (params.comment && params.comment.length > 150) {
		invalidFields.value['comment'] = ERROR_TYPES.too_long;
	} else {
		delete invalidFields.value['comment'];
	}
}

const cartItemCheckoutValidate = (item, key) => {
	const isRetail = isProductRetail(item.voucher);

	if (!isRetail && item.checkoutInfo.deliver !== 'RECIPIENT' && item.checkoutInfo.deliver !== 'ME') {
		invalidFields.value['deliver' + key] = ERROR_TYPES.required;
	} else {
		delete invalidFields.value['deliver' + key];
	}

	if (!isRetail && item.checkoutInfo.deliver === 'RECIPIENT') {
		if (!item.checkoutInfo.recipientEmail) {
			invalidFields.value['recipientEmail' + key] = ERROR_TYPES.required;
		} else if (!validEmail(item.checkoutInfo.recipientEmail)) {
			invalidFields.value['recipientEmail' + key] = ERROR_TYPES.invalid_email;
		} else {
			delete invalidFields.value['recipientEmail' + key];
		}
	} else {
		delete invalidFields.value['recipientEmail' + key];
	}

	if (isRetail && item.checkoutInfo.isGift && !item.checkoutInfo.recipientName) {
		invalidFields.value['recipientName' + key] = ERROR_TYPES.required;
	} else {
		delete invalidFields.value['recipientName' + key];
	}

	if (!isRetail || item.checkoutInfo.isGift) {
		if (!item.checkoutInfo.details || item.checkoutInfo.details.length < 2) {
			invalidFields.value['details' + key] = ERROR_TYPES.too_short;
		} else if (item.checkoutInfo.details.length > 120) {
			invalidFields.value['details' + key] = ERROR_TYPES.too_long;
		} else if ((item.checkoutInfo.details.match(/\n/g) || []).length > 1) {
			invalidFields.value['details' + key] = ERROR_TYPES.too_match_new_lines;
		} else {
			delete invalidFields.value['details' + key];
		}
	} else {
		delete invalidFields.value['details' + key];
	}

	if (!isRetail) {
		if (!item.checkoutInfo.firstName) {
			invalidFields.value['firstName' + key] = ERROR_TYPES.required;
		} else if (item.checkoutInfo.firstName.length < 2) {
			invalidFields.value['firstName' + key] = ERROR_TYPES.too_short;
		} else if (item.checkoutInfo.firstName.length > 50) {
			invalidFields.value['firstName' + key] = ERROR_TYPES.too_long;
		} else {
			delete invalidFields.value['firstName' + key];
		}

		if (!item.checkoutInfo.lastName) {
			invalidFields.value['lastName' + key] = ERROR_TYPES.required;
		} else if (item.checkoutInfo.lastName.length < 2) {
			invalidFields.value['lastName' + key] = ERROR_TYPES.too_short;
		} else if (item.checkoutInfo.lastName.length > 50) {
			invalidFields.value['lastName' + key] = ERROR_TYPES.too_long;
		} else {
			delete invalidFields.value['lastName' + key];
		}
	} else {
		delete invalidFields.value['firstName' + key];
		delete invalidFields.value['lastName' + key];
	}

	if (!isRetail && item.checkoutInfo.showAmount !== 'no' && item.checkoutInfo.showAmount !== 'yes') {
		invalidFields.value['showAmount' + key] = ERROR_TYPES.required;
	} else {
		delete invalidFields.value['showAmount' + key];
	}

};

const validatePaymentInfo = (paymentInfo) => {
	if (!paymentInfo.title) {
		invalidFields.value['title'] = ERROR_TYPES.required;
	} else {
		delete invalidFields.value['title'];
	}

	if (!paymentInfo.country) {
		invalidFields.value['country'] = ERROR_TYPES.required;
	} else {
		delete invalidFields.value['country'];
	}
	['street', 'house', 'postcode', 'city', 'country'].forEach((field) => {
		if (!paymentInfo[field]) {
			invalidFields.value[field] = ERROR_TYPES.required;
		} else {
			delete invalidFields.value[field];
		}
	});

	// shipping
	if (paymentInfo.deliverRetailTo !== 'ME') {
		['firstName', 'lastName', 'street', 'house', 'postcode', 'city', 'country'].forEach((field) => {
			field += 'Shipping';
			if (!paymentInfo[field]) {
				invalidFields.value[field] = ERROR_TYPES.required;
			} else {
				delete invalidFields.value[field];
			}
		});
	}


	if (!paymentInfo.firstName) {
		invalidFields.value['firstName'] = ERROR_TYPES.required;
	} else if (paymentInfo.firstName.length < 2) {
		invalidFields.value['firstName'] = ERROR_TYPES.too_short;
	} else if (paymentInfo.firstName.length > 50) {
		invalidFields.value['firstName'] = ERROR_TYPES.too_long;
	} else {
		delete invalidFields.value['firstName'];
	}

	if (!paymentInfo.phone) {
		invalidFields.value['phone'] = ERROR_TYPES.required;
	} else if (paymentInfo.phone.length < 7) {
		invalidFields.value['phone'] = ERROR_TYPES.too_short;
	} else if (paymentInfo.phone.length > 25) {
		invalidFields.value['phone'] = ERROR_TYPES.too_long;
	} else {
		delete invalidFields.value['phone'];
	}

	if (!paymentInfo.lastName) {
		invalidFields.value['lastName'] = ERROR_TYPES.required;
	} else if (paymentInfo.lastName.length < 2) {
		invalidFields.value['lastName'] = ERROR_TYPES.too_short;
	} else if (paymentInfo.lastName.length > 50) {
		invalidFields.value['lastName'] = ERROR_TYPES.too_long;
	} else {
		delete invalidFields.value['lastName'];
	}

	if (!paymentInfo.email) {
		invalidFields.value['email'] = ERROR_TYPES.required;
	} else if (!validEmail(paymentInfo.email)) {
		invalidFields.value['email'] = ERROR_TYPES.invalid_email;
	} else {
		delete invalidFields.value['email'];
	}
}

export {
	cartItemCheckoutValidate,
	isFieldInvalid,
	getFieldError,
	isFormInvalid,
	clear,
	redemptionParamsValidate,
	validatePaymentInfo,
	scrollToErrorElement
};