<template>
  <header-line></header-line>
  <div class="content" v-bind:class="{'header_wo_logo': data.hideHeaderLogo}">
    <div class="site-width">
      <div class="checkout__page">

        <checkout-header-line step="1"></checkout-header-line>

        <div class="checkout__content">
          <div class="checkout__info">
            <div class="form" v-for="(cartItem, itemKey) in cartItems" v-bind:key="itemKey">
              <checkout-voucher v-bind:cartItem="cartItem"
                                v-bind:position="cartItems.length > 1 ? itemKey + 1 : 0"
                                v-bind:isPreviewButtonHidden="isProductRetail(cartItem.voucher) && !cartItem.checkoutInfo.isGift"
                                :ref="el => { if (el) checkoutVoucherRefs[itemKey] = el }"
              ></checkout-voucher>
              <div class="checkout__form">

                <div v-if="isProductRetail(cartItem.voucher) && cartItem.voucher.giftEnabled">
                  <div class="form__title" v-bind:id="'packaging' + itemKey + 'container'">
                    {{ cartItem.checkoutInfo.isGift ? '1.' : '' }} {{ t('label.packaging') }}
                  </div>
                  <div class="form__row--error" v-if="isFieldInvalid('packaging' + itemKey)">
                    {{ t('error.please_select', {name: 'label.packaging'}) }}
                  </div>
                  <div class="form__row form__row--radio form__row--columns hidden-fields">
                    <div class="form__row form__row--column2"
                         v-bind:class="{'error': isFieldInvalid('packaging' + itemKey)}"
                    >
                      <input class="form__input form__input--radio hide-hidden" type="radio" value=""
                             v-bind:name="'packaging' + itemKey"
                             v-bind:id="'packaging_reg' + itemKey"
                             v-model="cartItem.checkoutInfo.isGift"
                             v-on:change="clearErrors('packaging' + itemKey)"
                      >
                      <label class="form__label form__label--radio" v-bind:for="'packaging_reg' + itemKey">
                        {{ t('label.regular') }}
                      </label>
                    </div>
                    <div class="form__row form__row--column2"
                         v-bind:class="{'error': isFieldInvalid('packaging' + itemKey)}"
                    >
                      <input class="form__input form__input--radio hide-hidden" type="radio" value="1"
                             v-bind:name="'packaging' + itemKey"
                             v-bind:id="'packaging_gift' + itemKey"
                             v-model="cartItem.checkoutInfo.isGift"
                             v-on:change="clearErrors('packaging' + itemKey)"
                      >
                      <label class="form__label form__label--radio" v-bind:for="'packaging_gift' + itemKey">
                        {{ t('label.gift') + (cartItem.voucher.giftSurcharge
                            ? (' (+' + cartItem.voucher.giftSurcharge + data.currencySymbol + ' ' + t('label.gift_surcharge') + ')')
                            : '')
                        }}
                      </label>
                    </div>
                  </div>
                  <div class="form__separator" v-if="cartItem.checkoutInfo.isGift"></div>
                </div>

                <div v-if="cartItem.checkoutInfo.isGift || !isProductRetail(cartItem.voucher)">
                  <div class="form__title" v-bind:id="'details' + itemKey + 'container'">
                    {{ cartItem.checkoutInfo.isGift ? '2' : '1' }}. {{ t('label.personal_message') }}
                    <svg class="icon i24x24 cursor-pointer" v-on:click="showInfo('personal_message')">
                      <use xlink:href="#i-info"></use>
                    </svg>
                  </div>

                  <div class="form__row" v-if="isProductRetail(cartItem.voucher) && cartItem.checkoutInfo.isGift">
                    <label class="form__label" v-bind:for="'recipient_name_id' + itemKey">
                      {{ t('label.recipient_name') }}
                    </label>
                    <input class="form__input" type="text"
                           v-bind:name="'recipient_name' + itemKey"
                           v-bind:id="'recipient_name_id' + itemKey"
                           v-model="cartItem.checkoutInfo.recipientName"
                           v-on:keyup="clearErrors('recipientName' + itemKey)"
                    >
                    <div class="error" v-if="isFieldInvalid('recipientName' + itemKey)">
                      {{ t(getFieldError('recipientName' + itemKey)) }}
                    </div>
                  </div>

                  <div class="form__row" v-bind:class="{'error': isFieldInvalid('details' + itemKey)}">
                    <label class="form__label"
                           v-bind:for="'details' + itemKey"
                    >{{ cartItem.voucher.giftEnabled ? t('label.message_on_retail') : t('label.message_on_voucher') }}</label>
                    <textarea class="form__textarea"
                              v-bind:name="'details' + itemKey"
                              v-bind:id="'details' + itemKey"
                              v-model="cartItem.checkoutInfo.details"
                              v-on:keyup="clearErrors('details' + itemKey)"
                    ></textarea>
                    <div class="input__characters">
                    <span v-bind:style="{
                      color: cartItem.checkoutInfo.details && cartItem.checkoutInfo.details.length > 120 ? '#d0201b' : 'rgba(0, 0, 0, 0.6)'
                    }">
                      {{ cartItem.checkoutInfo.details ? cartItem.checkoutInfo.details.length : 0 }}/120.
                    </span>
                      <span v-bind:style="{
                      color: cartItem.checkoutInfo.details && (cartItem.checkoutInfo.details.match(/\n/g)||[]).length > 1 ? '#d0201b' : 'rgba(0, 0, 0, 0.6)'
                    }">
                      Maximum of two lines allowed.
                    </span>
                    </div>
                    <div class="form__row--error"
                         v-if="isFieldInvalid('details' + itemKey)"
                    >
                      {{ t(getFieldError('details' + itemKey)) }}
                    </div>
                  </div>
                  <div class="form__separator"></div>
                </div>

                <div v-if="!isProductRetail(cartItem.voucher)">
                  <div class="form__title" v-bind:id="'deliver' + itemKey + 'container'">
                    2. {{ t('label.delivery_to') }}
                    <svg class="icon i24x24 cursor-pointer" v-on:click="showInfo('delivery_to')">
                      <use xlink:href="#i-info"></use>
                    </svg>
                  </div>
                  <div class="form__row--error"
                       v-if="isFieldInvalid('deliver' + itemKey)"
                  >
                    {{ t('error.please_select', {name: 'label.delivery_to'}) }}
                  </div>
                  <div class="form__row form__row--radio form__row--columns hidden-fields">
                    <div class="form__row form__row--column2"
                         v-bind:class="{'error': isFieldInvalid('deliver' + itemKey)}"
                    >
                      <input class="form__input form__input--radio hide-hidden" type="radio" value="ME"
                             v-bind:name="'deliver' + itemKey"
                             v-bind:id="'deliver_me' + itemKey"
                             v-model="cartItem.checkoutInfo.deliver"
                             v-on:change="clearErrors('deliver' + itemKey, 'recipientEmail' + itemKey)"
                      >
                      <label class="form__label form__label--radio"
                             v-bind:for="'deliver_me' + itemKey"
                      >
                        {{ t('label.me') }}
                      </label>
                      <div class="input__info">{{ isProductRetail(cartItem.voucher) ? t('message.info.me_retail') : t('message.info.me') }}</div>
                    </div>
                    <div class="form__row form__row--column2 hidden-field"
                         v-bind:id="'recipientEmail' + itemKey + 'container'"
                         v-bind:class="{
                        'error': isFieldInvalid('deliver' + itemKey) || isFieldInvalid('recipientEmail' + itemKey)
                      }"
                    >
                      <input class="form__input form__input--radio show-hidden" type="radio" value="RECIPIENT"
                             v-bind:name="'deliver' + itemKey"
                             v-bind:id="'deliver_recipient' + itemKey"
                             v-model="cartItem.checkoutInfo.deliver"
                             v-on:change="clearErrors('deliver' + itemKey, 'recipientEmail' + itemKey)"
                      >
                      <label class="form__label form__label--radio"
                             v-bind:for="'deliver_recipient' + itemKey"
                      >
                        {{ isProductRetail(cartItem.voucher) ? t('label.recipient_retail') : t('label.recipient') }}
                      </label>
                      <div class="input__info">{{ t('message.info.recipient_retail') }}</div>
                      <label class="form__label hidden"
                             v-if="!isProductRetail(cartItem.voucher)"
                             v-bind:class="{show: cartItem.checkoutInfo.deliver === 'RECIPIENT'}"
                             v-bind:for="'recipient_id' + itemKey"
                      >{{ t('label.recipient_email') }}</label>
                      <input class="form__input hidden" type="text"
                             v-if="!isProductRetail(cartItem.voucher)"
                             v-bind:class="{show: cartItem.checkoutInfo.deliver === 'RECIPIENT'}"
                             v-bind:name="'recipient_email' + itemKey"
                             v-bind:id="'recipient_id' + itemKey"
                             v-model="cartItem.checkoutInfo.recipientEmail"
                             v-on:keyup="clearErrors('recipientEmail' + itemKey)"
                      >
                      <div class="error" v-if="isFieldInvalid('recipientEmail' + itemKey)">
                        {{ t(getFieldError('recipientEmail' + itemKey)) }}
                      </div>
                    </div>
                  </div>
                  <div class="form__separator"
                       v-if="!isProductRetail(cartItem.voucher) || cartItem.checkoutInfo.deliver === 'RECIPIENT' || cartItem.checkoutInfo.isGift"
                  ></div>
                </div>

                <div v-if="!isProductRetail(cartItem.voucher)">
                  <div class="form__title">
                    3. {{ t('label.recipient_details') }}
                    <svg class="icon i24x24 cursor-pointer" v-on:click="showInfo('recipient_details')">
                      <use xlink:href="#i-info"></use>
                    </svg>
                  </div>
                  <div class="form__row form__row--columns">
                    <div class="form__row form__row--column2"
                         v-bind:class="{'error': isFieldInvalid('firstName' + itemKey)}"
                         v-bind:id="'firstName' + itemKey + 'container'"
                    >
                      <label class="form__label"
                             v-bind:for="'fname' + itemKey"
                      >{{ t('label.recipient_first_name') }}</label>
                      <input class="form__input" type="text"
                             v-bind:name="'fname' + itemKey"
                             v-bind:id="'fname' + itemKey"
                             v-model="cartItem.checkoutInfo.firstName"
                             v-on:keyup="clearErrors('firstName' + itemKey)"
                      >
                      <div class="error" v-if="isFieldInvalid('firstName' + itemKey)">
                        {{ t(getFieldError('firstName' + itemKey)) }}
                      </div>
                    </div>
                    <div class="form__row form__row--column2"
                         v-bind:class="{'error': isFieldInvalid('lastName' + itemKey)}"
                         v-bind:id="'lastName' + itemKey + 'container'"
                    >
                      <label class="form__label"
                             v-bind:for="'lname' + itemKey"
                      >{{ t('label.recipient_last_name') }}</label>
                      <input class="form__input" type="text"
                             v-bind:name="'lname' + itemKey"
                             v-bind:id="'lname' + itemKey"
                             v-model="cartItem.checkoutInfo.lastName"
                             v-on:keyup="clearErrors('lastName' + itemKey)"
                      >
                      <div class="error" v-if="isFieldInvalid('lastName' + itemKey)">
                        {{ t(getFieldError('lastName' + itemKey)) }}
                      </div>
                    </div>
                  </div>
                  <div class="form__separator"
                       v-if="cartItem.voucher.priceType !== 'STEP' && !isProductRetail(cartItem.voucher)"></div>
                </div>

                <div v-if="!isProductRetail(cartItem.voucher)">
                  <div class="form__title" v-bind:id="'showAmount' + itemKey + 'container'"
                       v-if="cartItem.voucher.priceType !== 'STEP'"
                  >
                    4. {{ t('label.voucher_value') }}
                    <svg class="icon i24x24 cursor-pointer" v-on:click="showInfo('voucher_value')">
                      <use xlink:href="#i-info"></use>
                    </svg>
                  </div>
                  <div class="form__row--error" v-if="isFieldInvalid('showAmount' + itemKey)">
                    {{ t('error.please_select', {name: 'label.voucher_value'}) }}
                  </div>

                  <div class="form__row form__row--radio  form__row--columns" v-if="cartItem.voucher.priceType !== 'STEP'">
                    <div class=" form__row  form__row--column2"
                         v-bind:class="{'error': isFieldInvalid('showAmount' + itemKey)}"
                    >
                      <input class="form__input form__input--radio" type="radio" value="no"
                             v-bind:name="'show_amount' + itemKey"
                             v-bind:id="'no_amount' + itemKey"
                             v-model="cartItem.checkoutInfo.showAmount"
                             v-on:change="clearErrors('showAmount' + itemKey)"
                      >
                      <label class="form__label form__label--radio"
                             v-bind:for="'no_amount' + itemKey"
                      >
                        {{ t('label.do_not_show_amount') }}
                      </label>
                    </div>
                    <div class="form__row form__row--column2"
                         v-bind:class="{'error': isFieldInvalid('showAmount' + itemKey)}"
                    >
                      <input class="form__input form__input--radio" type="radio" value="yes"
                             v-bind:name="'show_amount' + itemKey"
                             v-bind:id="'show_amount' + itemKey"
                             v-model="cartItem.checkoutInfo.showAmount"
                             v-on:change="clearErrors('showAmount' + itemKey)"
                      >
                      <label class="form__label form__label--radio"
                             v-bind:for="'show_amount' + itemKey"
                      > {{ t('label.show_amount') }} </label>
                    </div>
                  </div>
                </div>

                <div v-if="cartItem.checkoutInfo.isGift || !isProductRetail(cartItem.voucher)">
                  <div class="form__row form__row--acenter form__row--btns">
                    <button class="btns btn-view btn-orange" @click.prevent="checkoutVoucherRefs[itemKey].showPreview()"
                            title="Preview printing PDF"
                    >
                      <svg class="icon i24x24 cursor-pointer"><use xlink:href="#i-eye"></use></svg> {{ t('label.preview') }}
                    </button>
                  </div>
                </div>
                <div class="form__separator"></div>
              </div>
            </div>
          </div>
          <div class="checkout__order">
            <div class="order">
              <div class="order__title">{{ t('label.your_order') }}</div>
              <div class="order__table">
                <table class="table" width="100%" cellspacing="0" cellpadding="0">
                  <tbody>
                  <tr class="table__row">
                    <th class="table__cell table__cell--header">{{ t('label.item') }}</th>
                    <th class="table__cell table__cell--header">{{ t('label.quantity_short') }}</th>
                    <th class="table__cell table__cell--header">{{ t('label.price') }}</th>
                  </tr>
                  <tr class="table__row" v-for="cartItem in groupedCartItems" v-bind:key="cartItem.data.voucher.id">
                    <td class="table__cell">{{ cartItem.data.voucher.name }}</td>
                    <td class="table__cell">{{ cartItem.count }}</td>
                    <td class="table__cell">{{ numberFormat(getCartItemPrice(cartItem, false), data.currencySymbol) }}</td>
                  </tr>
                  <tr class="table__row" v-if="getCartRetailTotalTax(cartItems) > 0">
                    <td class="table__cell text-right" colspan="2">{{ data.retailTaxDescription || t('label.total_tax') }}</td>
                    <td class="table__cell">{{ numberFormat(getCartRetailTotalTax(cartItems), data.currencySymbol) }}</td>
                  </tr>

                  <tr class="table__row" v-if="getCartGiftTotal(cartItems) > 0">
                    <td class="table__cell text-right" colspan="2">{{t('label.total_gift_price') }}</td>
                    <td class="table__cell">{{ numberFormat(getCartGiftTotal(cartItems), data.currencySymbol) }}</td>
                  </tr>
                  <tr class="table__row" v-if="getCartShippingTotal(cartItems) > 0">
                    <td class="table__cell text-right" colspan="2">{{t('label.shipping_cost') }}</td>
                    <td class="table__cell">{{ numberFormat(getCartShippingTotal(cartItems), data.currencySymbol) }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="order__total">
                <span class="label">{{ t('label.total_price') }}</span>
                {{ numberFormat(getCartTotalPrice(cartItems), data.currencySymbol) }}
              </div>
              <div class="order__btns">
                <button class="btns btn-orange"
                        v-on:click="proceedToPayment"
                >
                  {{ t('button.proceed_to_payment') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <popup v-on:hide-popup="showInfoPopup = false" v-if="showInfoPopup" additional-classes="auto-height">
    <template v-slot:content>
      <div v-html="t('message.info.' + currentInfoType)"></div>
    </template>
  </popup>
  <footer-line></footer-line>
</template>

<script>
import HeaderLine from "@/components/HeaderLine";
import CheckoutHeaderLine from "@/components/CheckautHeaderLine";
import {useStore} from 'vuex'
import {computed, watchEffect, onMounted, ref} from 'vue'
import {useI18n} from "vue-i18n";
import FooterLine from "@/components/FooterLine";
import CheckoutVoucher from "@/components/checkout/Voucher";
import {useRouter, useRoute} from "vue-router";
import {numberFormat} from "@/helpers/PriceHelper";
import {
  cartItemCheckoutValidate,
  isFieldInvalid,
  isFormInvalid,
  clear,
  getFieldError,
  scrollToErrorElement
} from "@/helpers/ValidatorHelper";
import {ACTION_TYPES} from "@/constants";
import Popup from "@/components/Popup";
import usePrice from "@/helpers/usePrice";
import CustomSelect from "@/components/CustomSelect";
import { isProductRetail } from "@/helpers/ProductHelper";

export default {
  name: 'checkout',
  components: {Popup, CheckoutVoucher, FooterLine, CustomSelect, CheckoutHeaderLine, HeaderLine},
  setup() {
    const store = useStore();
    const data = computed(() => store.getters.data);
    const cartItems = computed(() => store.getters.cartItems);
    const groupedCartItems = computed(() => store.getters.groupedCartItems);
    const router = useRouter();
    const route = useRoute();
    const showInfoPopup = ref(false);
    const currentInfoType = ref(null);
    const checkoutVoucherRefs = ref([]);

    const shippingCountries = computed(() => {
      const countries = {};
      data.value.shippingCountries && data.value.shippingCountries.forEach((el) => {
        countries[el.name] = el.name;
      });

      return countries;
    });

    const {
      getCartItemPrice, getCartTotalPrice, getCartTotalTax, getCartRetailTotalTax,
      getCartGiftTotal, getCartShippingTotal
    } = usePrice();

    // check not empty cart
    watchEffect(() => {
      if (data.value && !cartItems.value.length) {
        router.push({name: 'home', params: route.params});
      }
    })

    onMounted(() => {
      if (!route.params.with_error) {
        clear();
      } else {
        scrollToErrorElement();
      }
      cartItems.value.forEach((item) => {
        if (item.voucher.priceType === 'STEP') {
            item.checkoutInfo.showAmount = 'yes';
        }
      });
    });
    const showInfo = (type) => {
      currentInfoType.value = type;
      showInfoPopup.value = true;
    };
    const proceedToPayment = () => {
      // validations
      cartItems.value.forEach((item, key) => cartItemCheckoutValidate(item, key));

      store.dispatch(ACTION_TYPES.syncWithStorage);
      if (!isFormInvalid()) {
        router.push({name: 'payment', params: route.params});
      } else {
        scrollToErrorElement();
      }
    }
    const i18n = useI18n();

    return {
      t: i18n.t,
      cartItems,
      data,
      shippingCountries,
      isProductRetail,
      getCartItemPrice,
      getCartTotalPrice,
      getCartTotalTax,
      getCartRetailTotalTax,
      getCartShippingTotal,
      getCartGiftTotal,
      groupedCartItems,
      isFieldInvalid,
      getFieldError,
      proceedToPayment,
      numberFormat,
      showInfoPopup,
      showInfo,
      currentInfoType,
      checkoutVoucherRefs,
      clearErrors: clear
    }
  }
}
</script>

<style>
  .text-right {
    text-align: right!important;
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>