<template>
  <div class="checkout__card">
    <div class="card">
      <figure class="card__image">
        <span class="card__link--image">
          <span class="label" v-if="position">{{ position }}</span>
          <img v-if="cartItem.voucher.image_1x"
              v-bind:src="cartItem.voucher.image_1x.src"
              v-bind:alt="cartItem.voucher.name"
              v-bind:srcset="cartItem.voucher.image_2x.src +' 2x, ' + cartItem.voucher.image_3x.src + ' 3x'"
          >
        </span>
      </figure>
      <div class="card__block">
        <div class="card__title">{{ cartItem.voucher.name }}</div>
        <div class="card__options" v-if="cartItem.voucher.priceType === 'VARIANT' || cartItem.voucher.multipleOptions">
          <div class="card__label">{{ t('label.option') }}</div>
          <p v-if="cartItem.voucher.multipleOptions">{{ cartItem.option }}</p>
          <p v-else>
            {{ getSelectOptions(cartItem.voucher)[cartItem.option] }}
          </p>
        </div>
        <div class="card__price">
          <div class="card__label">{{ t('label.retail_value') }}</div>
          {{ numberFormat(getProductVariantPrice(cartItem.voucher, cartItem.option, true), data.currencySymbol) }}
          <div class="card__label" v-if="isProductRetail(cartItem.voucher) && data.retailTaxPercentage">
            {{ data.pricesTaxInclusive ? t('label.tax_including') : t('label.tax_excluding') }}
            {{ data.retailTaxDescription + ' ' + data.retailTaxPercentage }}%
          </div>
        </div>
        <div class="card__btns" v-if="!isPreviewButtonHidden">
          <button class="btns btn-view"
                  title="Preview printing PDF"
                  v-on:click.prevent="showPreview"
          >
            <svg class="icon i24x24">
              <use xlink:href="#i-eye"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <popup additional-classes="pdf-preview-popup"
           v-on:hide-popup="hidePreview"
           v-if="isPreviewOpened"
    >
      <template v-slot:content><iframe class="preview-container" v-bind:src="previewPath"></iframe></template>
    </popup>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from 'vue'
import {useI18n} from 'vue-i18n'
import {getSelectOptions} from "@/helpers/VoucherVariantsHelper";
import {ACTION_TYPES} from "@/constants";
import {getPDFPreviewPath} from '@/helpers/RequestParamsHelper';
import {numberFormat} from '@/helpers/PriceHelper';
import Popup from "@/components/Popup";
import usePrice from "@/helpers/usePrice";
import { isProductRetail } from "@/helpers/ProductHelper";

export default {
  name: 'checkout-voucher',
  components: {Popup},
  setup(props) {
    const store = useStore();
    const data = computed(() => store.getters.data);
    const isPreviewOpened = ref(false);
    const previewPath = ref(null);
    const {getProductVariantPrice} = usePrice();

    const showPreview = () => {
      store.dispatch(ACTION_TYPES.syncWithStorage);
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      previewPath.value = getPDFPreviewPath(props.cartItem);

      isPreviewOpened.value = true;
    }
    const hidePreview = () => {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      previewPath.value = null;
      isPreviewOpened.value = false;
    }
    const i18n = useI18n();

    return {
      t: i18n.t,
      numberFormat,
      getSelectOptions,
      isPreviewOpened,
      showPreview,
      hidePreview,
      previewPath,
      getProductVariantPrice,
      isProductRetail,
      data
    }
  },
  props: {
    cartItem: {
      type: [Array, Object],
      required: true
    },
    position: {
      type: Number,
      required: false,
      default: 0
    },
    isPreviewButtonHidden: {
      type: Boolean,
      required: false,
      default: false
    },
  }
};
</script>