import { isRef } from 'vue'
import { isProductRetail } from "@/helpers/ProductHelper";

const getCartItemMakePaymentParams = (cartItem, paymentInfo) => {
	const item = isRef(cartItem) ? cartItem.value : cartItem;
	const deliverToRecipient = item.checkoutInfo.deliver === 'RECIPIENT';

	return {
		id: item.voucher.id,
		deliverTo: item.checkoutInfo.deliver,
		recipientFirstName: item.checkoutInfo.firstName || paymentInfo.firstName,
		recipientLastName: item.checkoutInfo.lastName || paymentInfo.lastName,
		recipientName: item.checkoutInfo.recipientName || '', // gift retail
		deliveryEmail: deliverToRecipient
			? item.checkoutInfo.recipientEmail
			: paymentInfo.email,
		showAmount: item.checkoutInfo.showAmount,
		voucherMessage: item.checkoutInfo.details || '',
		variant: item.option || '',
		isGift: !!item.checkoutInfo.isGift,
	}
};

const getPDFPreviewPath = (cartItem) => {
	const item = isRef(cartItem) ? cartItem.value : cartItem;
	let type = 'page';
	if (isProductRetail(item.voucher)) {
		type = 'gift';
	}
	return '/api/pdf/' + type + '?id=' + item.voucher.id
		+ '&variant=' + encodeURIComponent(item.option)
		+ '&recipientFirstName=' + encodeURIComponent(item.checkoutInfo.firstName || '')
		+ '&recipientLastName=' + encodeURIComponent(item.checkoutInfo.lastName || '')
		+ '&recipientName=' + encodeURIComponent(item.checkoutInfo.recipientName || '')
		+ '&deliveryEmail='
		+ (item.checkoutInfo.deliver === 'recipient'
			? (item.checkoutInfo.recipientEmail || 'Your Email')
			: 'Your Email')
		+ '&voucherMessage=' + encodeURIComponent(item.checkoutInfo.details || '')
		+ '&displayValue=' + (item.checkoutInfo.showAmount === 'yes' ? 1 : 0)
		+ '&topdf=1';
}
export {getCartItemMakePaymentParams, getPDFPreviewPath};